<template>
    <div class="accountform">
      <img src="../../assets/account.png" class="accountBack">
      <div class="contentBack">
          <el-form :model="form" ref="form" :rules="formRules">
              <div class="row">
                  <div class="rowItem">
                      <el-form-item label="Name of Agency" prop="agencyName">
                          <el-input style="width:500px" v-model="form.agencyName"></el-input>
                      </el-form-item>
                  </div>
                  <div class="rowItem" >
                      <el-form-item label="Website" prop="web">
                          <el-input style="width:500px" v-model="form.web"></el-input>
                      </el-form-item>
                  </div>
              </div>
              <div class="row">
                  <el-form-item label="Agency Type" prop="agencyType">
                      <div style="display: inline-block;width:100%">
                          <el-checkbox-group v-model="form.agencyType">
                              <div style="width:500px"><el-checkbox label="Oversea Education Intermediary" /></div>
                              <div style="width:500px"><el-checkbox label="Personal Work Studio" /></div>
                              <div style="width:500px"><el-checkbox label="Institution of Background Improvement" /></div>
                              <div style="width:500px"><el-checkbox label="Others"/></div>
                          </el-checkbox-group>
                      </div>
                  </el-form-item>
              </div>
              <div class="row">
                  <el-form-item label="Where do you learn about PIE?" prop="where">
                      <div style="display: inline-block;width:100%">
                          <el-radio-group v-model="form.where">
                              <div style="width:500px;margin-bottom: 18px;"><el-radio label="Website Search" /></div>
                              <div style="width:500px;margin-bottom: 18px;"><el-radio label="Social Media" /></div>
                              <div style="width:500px;margin-bottom: 18px;"><el-radio label="Students’ Recommendation" /></div>
                              <div style="width:500px;margin-bottom: 18px;"><el-radio label="Recommendations of Cooperative Partners"/></div>
                              <div style="width:500px"><el-radio label="Others"/></div>
                          </el-radio-group>
                      </div>
                  </el-form-item>
              </div>
              <div class="row">
                  <div class="rowItem">
                      <el-form-item label="Contact Address" prop="contact_email">
                          <el-input style="width:500px" v-model="form.contact_email"></el-input>
                      </el-form-item>
                  </div>
                  <div class="rowItem" >
                      <el-form-item label="Registrant’s Name" prop="regName">
                          <el-input style="width:500px" v-model="form.regName"></el-input>
                      </el-form-item>
                  </div>
              </div>
              <div class="row">
                  <div class="rowItem">
                      <el-form-item label="Registrant’s Mobile" prop="regMobile">
                          <el-input style="width:500px" v-model="form.regMobile"></el-input>
                      </el-form-item>
                  </div>
                  <div class="rowItem" >
                      <el-form-item label="Registrant’s IM (Please also specify the IM platform)" prop="regWechat">
                          <el-input style="width:500px" v-model="form.regWechat"></el-input>
                      </el-form-item>
                  </div>
              </div>
              <div class="row">
                  <div class="rowItem">
                      <el-form-item label="Registrant’s Email Address" prop="regEmail">
                          <el-input style="width:500px" v-model="form.regEmail"></el-input>
                      </el-form-item>
                  </div>
                  <div class="rowItem" >
                      <el-form-item label="Registrant’s Title" prop="regTitle">
                          <el-input style="width:500px" v-model="form.regTitle"></el-input>
                      </el-form-item>
                  </div>
              </div>
              <div class="row">
                  <div class="rowItem">
                      <el-form-item label="Are you also the program adviser?" prop="adviserStatus">
                          <el-input style="width:500px" v-model="form.adviserStatus"></el-input>
                      </el-form-item>
                  </div>
              </div>
              <div class="row" style="font-size: 16px;color: #333333;font-weight: bold;">Program Adviser</div>
              <div class="row" style="color:#666666">If you are not the adviser，please provide the adviser’s information in the following form.</div>
              <div class="row">
                  <div class="rowItem">
                      <el-form-item label="Name">
                          <el-input style="width:500px" v-model="form.adviserName"></el-input>
                      </el-form-item>
                  </div>
                  <div class="rowItem" >
                      <el-form-item label="Title">
                          <el-input style="width:500px" v-model="form.adviserTitle"></el-input>
                      </el-form-item>
                  </div>
              </div>
              <div class="row">
                  <div class="rowItem">
                      <el-form-item label="Email">
                          <el-input style="width:500px" v-model="form.adviserEmail"></el-input>
                      </el-form-item>
                  </div>
                  <div class="rowItem" >
                      <el-form-item label="Wechat">
                          <el-input style="width:500px" v-model="form.wechat"></el-input>
                      </el-form-item>
                  </div>
              </div>
              <div class="row" style="margin-bottom:50px">
                  <span class="submit" @click="submit">Submit</span>
              </div>
          </el-form>
      </div>
    </div>
  </template>

<script>
import { saveAccountForm } from '../../api/index'
export default {
  data () {
    return {
      form: {
        agencyName: '',
        web: '',
        agencyType: [],
        where: [],
        contact_email: '',
        regName: '',
        regMobile: '',
        regWechat: '',
        regEmail: '',
        regTitle: '',
        adviserStatus: '',
        adviserName: '',
        adviserTitle: '',
        adviserEmail: '',
        wechat: ''
      },
      formRules: {
        agencyName: [
          { required: true, message: 'Please input', trigger: 'blur' }
        ],
        web: [
          { required: true, message: 'Please input', trigger: 'blur' }
        ],
        agencyType: [
          { required: true, message: 'Please select', trigger: 'change' }
        ],
        where: [
          { required: true, message: 'Please select', trigger: 'change' }
        ],
        contact_email: [
          { required: true, message: 'Please input', trigger: 'blur' }
        ],
        regName: [
          { required: true, message: 'Please input', trigger: 'blur' }
        ],
        regMobile: [
          { required: true, message: 'Please input', trigger: 'blur' }
        ],
        regWechat: [
          { required: true, message: 'Please input', trigger: 'blur' }
        ],
        regEmail: [
          { required: true, message: 'Please input', trigger: 'blur' }
        ],
        regTitle: [
          { required: true, message: 'Please input', trigger: 'blur' }
        ],
        adviserStatus: [
          { required: true, message: 'Please input', trigger: 'blur' }
        ]
      }
    }
  },

  methods: {
    submit () {
      this.$refs.form.validate((val) => {
        if (val) {
          saveAccountForm(this.form).then(res => {
            if (res.data) {
              this.$message.success('Submit Successfully')
            }
          })
        }
      })
    }
  }
}
</script>

  <style scoped lang="scss">
  .accountform{
      width: 100%;
      font-family: "DDINAlternateRegular";
      overflow: hidden;
      .accountBack{
          width: 100%;
          display: block;
      }
      .contentBack{
          background: url(../../assets/accountBack.png);
          background-position: 0 0;
          background-size: 100% 100%;
          overflow: hidden;
          padding-top: 60px;
      }
      .row{
          width: 1160px;
          margin: 0 auto;
          justify-content: space-between;
          margin-bottom: 20px;
          display: flex;
          .rowItem{

          }
      }
      .el-checkbox-group{
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
      }
      .el-radio-group{
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
      }
      .submit{
          padding: 10px 70px;
          background: #F55443;
          color: #fff;
          cursor: pointer;
      }
  }
  </style>
  <style lang="scss">
  .accountform{
      .el-form-item__label{
          font-size: 16px;
          color: #333333;
          font-weight: bold;
      }
      .el-checkbox__label{
          font-size: 16px;
          color: #666666;
      }
      .el-radio__label{
          font-size: 16px;
          color: #666666;
      }
      .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
          background-color: #F55443;
          border-color: #F55443;
      }
      .el-checkbox__input.is-checked+.el-checkbox__label{
          color: #F55443;
      }
      .el-radio__input.is-checked .el-radio__inner{
          background-color: #F55443;
          border-color: #F55443;
      }
      .el-radio__input.is-checked+.el-radio__label{
          color: #F55443;
      }
  }
  </style>
