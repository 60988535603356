<template>
    <div class="accountform">
      <Pc v-if="!isMobileStatus"></Pc>
      <Mobile v-else></Mobile>
    </div>
  </template>

<script>
import Pc from './pc.vue'
import Mobile from './mobile.vue'
export default {
  components: {
    Pc, Mobile
  },
  data () {
    return {
      isMobileStatus: false
    }
  },
  mounted () {
    this.isMobileStatus = this.isMobile()
  },
  methods: {
    isMobile () {
      const userAgentInfo = navigator.userAgent
      const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPod']
      const getArr = Agents.filter(i => userAgentInfo.includes(i))
      return !!getArr.length
    }
  }
}
</script>
